import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { AppConfigService } from './_mx/services/appconfig.service';
import { DateHelperService } from './_mx/services/helpers/date-helper/date-helper.service';
import { TimesheetHelperService } from './_mx/services/helpers/timesheet-helper/timesheet-helper.service';
import { HttpHeadersInterceptor } from './interceptors/http-headers.interceptor';
import { DatePipe } from '@angular/common';
import { PrimeNgModule } from './modules/primeng/primeng.module';
import { AuthService } from './_primeng/modules/auth/services/auth/auth.service';
import { AuthHTTPService } from './_primeng/modules/auth/services/auth/auth-http.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { SharedModule } from './_primeng/modules/shared/shared.module';



function appInitializer(authService: AuthService) {
  return () => authService.getUserByToken()
}

const configInitializer = (configService: AppConfigService) => {
  return () => {
    return configService.load();
  }
}

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    PrimeNgModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({}),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule
  ],
  exports: [PrimeNgModule],
  providers: [DialogService, ConfirmationService,
    AuthHTTPService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configInitializer,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
    DateHelperService,
    TimesheetHelperService,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
