// Germany
export const locale = {
  lang: "de",
  "data": {
    "COMMON": {
      "CANCEL": "Abbrechen",
      "SUBMIT": "Einreichen",
      "NO": "Nein",
      "YES": "Ja",
      "YES_CLOSE_AND_LOSE_CHANGES": "Ja, schließen und Änderungen verlieren",
      "CLOSE_AND_DISCARD": "Schließen und verwerfen",
      "SAVE_UPDATED_DETAILS": "Aktualisierte Details speichern",
      "VALIDATION": {
        "SOMETHING_WENT_WRONG": "Ups, etwas ist schief gelaufen! Bitte versuche es erneut.",
        "SUCCESSFULLY_UPDATED": "Erfolgreich aktualisiert",
        "ANY_CHANGES_MADE_WILL_BE_LOST": "Alle vorgenommenen Änderungen gehen verloren.",
        "ARE_YOU_SURE_YOU_WANT_TO_PROCEED": "Bist du sicher, dass du fortfahren möchtest?"
      }
    },
    "NAVBAR": {
      "HELLO": "Hallo",
      "MY_DETAILS": "Meine Details",
      "MY_CONTACT_METHODS": "Meine Kontaktmethoden",
      "MY_DATA_CONSENTS": "Meine Datenzustimmungen",
      "LOGOUT": "Abmelden",
      "SELECT_LANGUAGE": "Sprache wählen",
      "MENU": "Menü",
      "CLOSE": "Schließen",
      "SETTINGS": "Einstellungen",
      "TERMSANDCONDITIONS": "Geschäftsbedingungen",
      "LANGUAGE": {
        "ENLABEL": "Englisch",
        "CHLABEL": "Chinesisch",
        "ESLABEL": "Spanisch",
        "JALABEL": "Japanisch",
        "DELABEL": "Deutsch",
        "FRLABEL": "Französisch"
      },
      "MENU_ITEM": {
        "CURRENT_PLACEMENT": "Meine aktuelle Platzierung",
        "COMPLIANCE": "Meine Compliance",
        "TIMESHEETS": "Meine Stundenzettel",
        "EXPENSES": "Meine Ausgaben"
      }
    },
    "AUTH": {
      "LOGIN": {
        "BUTTON": {
          "SIGN_IN": "Anmelden"
        },
        "LABEL": {
          "EMAIL": "E-Mail",
          "PASSWORD": "Passwort",
          "FORGOT_PASSWORD": "Passwort vergessen?"
        },
        "VALIDATION": {
          "EMAIL_INVALID": "Ungültige E-Mail-Adresse",
          "EMAIL_MAX_LENGTH": "E-Mail darf nicht länger als {{value}} Zeichen sein",
          "EMAIL_REQUIRED": "E-Mail ist erforderlich",
          "LOGIN_FAILED": "Die Anmeldedaten sind falsch",
          "PASSWORD_MAX_LENGTH": "Passwort darf nicht länger als {{value}} Zeichen sein",
          "PASSWORD_MIN_LENGTH": "Passwort muss mindestens {{value}} Zeichen lang sein",
          "PASSWORD_REQUIRED": "Passwort ist erforderlich"
        }
      },
      "FORGOT_PASSWORD": {
        "ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD": "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
        "FORGOTTEN_PASSWORD": "Passwort vergessen?",
        "BUTTON": {
          "OK_GOT_IT": "Ok, verstanden!"
        },
        "LABEL": {
          "EMAIL": "E-Mail",
          "EMAIL_SENT_MESSAGE1": "Wir haben eine E-Mail an die von Ihnen angegebene Adresse gesendet, um Ihr Passwort zurückzusetzen.",
          "EMAIL_SENT_MESSAGE2": "Wenn Sie es nicht erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner und vergewissern Sie sich, dass die eingegebene E-Mail-Adresse korrekt war.",
          "EMAIL_SENT_TITLE": "Wiederherstellungs-E-Mail gesendet!",
          "SUCCESS": "Ihr Konto wurde erfolgreich zurückgesetzt."
        },
        "VALIDATION": {
          "EMAIL_INVALID": "Ungültige E-Mail-Adresse",
          "EMAIL_MAX_LENGTH": "E-Mail darf nicht länger als {{value}} Zeichen sein",
          "EMAIL_REQUIRED": "E-Mail ist erforderlich"
        }
      },
      "RESET_PASSWORD": {
        "ENTER_NEW_PASSWORD_DETAILS": "Geben Sie neue Passwortdetails ein",
        "RESET_PASSWORD": "Passwort zurücksetzen",
        "BUTTON": {},
        "LABEL": {
          "CONFIRM_NEW_PASSWORD": "Neues Passwort bestätigen",
          "CREATE_NEW_PASSWORD": "Neues Passwort erstellen",
          "OLD_PASSWORD": "Altes Passwort",
          "PASSWORD": "Passwort"
        },
        "VALIDATION": {
          "PASSWORDS_DONT_MATCH": "'Passwort' und 'Passwort bestätigen' stimmen nicht überein",
          "PASSWORD_MAX_LENGTH": "Passwort darf nicht länger als {{value}} Zeichen sein",
          "PASSWORD_MIN_LENGTH": "Passwort muss mindestens {{value}} Zeichen lang sein",
          "PASSWORD_PATTERN": "Muss Groß- und Kleinbuchstaben, eine Zahl und eines der folgenden Zeichen enthalten: -+_!@#$%^&*.,?",
          "PASSWORD_REQUIRED": "Passwort ist erforderlich",
          "REGISTRATION_DETAILS_INCORRECT": "Die Registrierungsdetails sind falsch"
        }
      }
    },
    "MY_CONTACT_METHODS": {
      "CONTACT_PREFERENCES": "Kontaktpräferenzen",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED": "Bitte wählen Sie, wie Sie kontaktiert werden möchten.",
      "BUTTON": {},
      "LABEL": {
        "RECEIVE": "Empfangen",
        "DO_NOT_RECEIVE": "Nicht empfangen",
        "SALES_EMAIL": "Vertriebs-E-Mail",
        "EMAIL": "E-Mail",
        "PHONE_CALL": "Anruf",
        "TEXT_MESSAGE": "Textnachricht"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "Beim Aktualisieren der Kontaktpräferenzen ist ein Fehler aufgetreten"
      }
    },
    "MY_DATA_CONSENTS": {
      "MY_DATA_CONSENTS": "Meine Datenzustimmungen",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_MANAGE_YOUR_DATA_CONSENTS": "Bitte wählen Sie aus, wie Sie Ihre Datenzustimmungen verwalten möchten.",
      "BUTTON": {},
      "LABEL": {
        "CONSENT": "Zustimmung",
        "WITHDRAW": "Widerrufen",
        "DATA_CONSENT_PURPOSE": "Zweck der Datenzustimmung",
        "CONSENT_METHOD": "Zustimmungsmethode",
        "CONSENT_GIVEN": "Zustimmung erteilt",
        "CONSENT_EXPIRY": "Zustimmungsablauf",
        "WITHDRAW_CONSENT": "Zustimmung widerrufen"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "Beim Aktualisieren meiner Datenzustimmungen ist ein Fehler aufgetreten"
      }
    }
  }
};
