// Japan
export const locale = {
  lang: "ja",
  "data": {
    "COMMON": {
      "CANCEL": "キャンセル",
      "SUBMIT": "送信",
      "NO": "いいえ",
      "YES": "はい",
      "YES_CLOSE_AND_LOSE_CHANGES": "はい、閉じて変更を破棄します",
      "CLOSE_AND_DISCARD": "閉じて破棄する",
      "SAVE_UPDATED_DETAILS": "更新された詳細を保存する",
      "VALIDATION": {
        "SOMETHING_WENT_WRONG": "おっと、何か問題が発生したようです！もう一度お試しください。",
        "SUCCESSFULLY_UPDATED": "正常に更新されました",
        "ANY_CHANGES_MADE_WILL_BE_LOST": "行われた変更はすべて失われます。",
        "ARE_YOU_SURE_YOU_WANT_TO_PROCEED": "続行してもよろしいですか？"
      }
    },
    "NAVBAR": {
      "HELLO": "こんにちは",
      "MY_DETAILS": "私の詳細",
      "MY_CONTACT_METHODS": "私の連絡方法",
      "MY_DATA_CONSENTS": "私のデータ同意",
      "LOGOUT": "ログアウト",
      "SELECT_LANGUAGE": "言語を選択",
      "MENU": "メニュー",
      "CLOSE": "閉じる",
      "SETTINGS": "設定",
      "TERMSANDCONDITIONS": "利用規約",
      "LANGUAGE": {
        "ENLABEL": "英語",
        "CHLABEL": "中国語",
        "ESLABEL": "スペイン語",
        "JALABEL": "日本語",
        "DELABEL": "ドイツ語",
        "FRLABEL": "フランス語"
      },
      "MENU_ITEM": {
        "CURRENT_PLACEMENT": "現在の配置",
        "COMPLIANCE": "コンプライアンス",
        "TIMESHEETS": "タイムシート",
        "EXPENSES": "経費"
      }
    },
    "AUTH": {
      "LOGIN": {
        "BUTTON": {
          "SIGN_IN": "サインイン"
        },
        "LABEL": {
          "EMAIL": "メール",
          "PASSWORD": "パスワード",
          "FORGOT_PASSWORD": "パスワードを忘れましたか？"
        },
        "VALIDATION": {
          "EMAIL_INVALID": "無効なメールアドレスです",
          "EMAIL_MAX_LENGTH": "メールは{{value}}文字を超えてはなりません",
          "EMAIL_REQUIRED": "メールは必須です",
          "LOGIN_FAILED": "ログイン情報が正しくありません",
          "PASSWORD_MAX_LENGTH": "パスワードは{{value}}文字を超えてはなりません",
          "PASSWORD_MIN_LENGTH": "パスワードは最低{{value}}文字でなければなりません",
          "PASSWORD_REQUIRED": "パスワードは必須です"
        }
      },
      "FORGOT_PASSWORD": {
        "ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD": "パスワードをリセットするためにメールアドレスを入力してください",
        "FORGOTTEN_PASSWORD": "パスワードを忘れましたか？",
        "BUTTON": {
          "OK_GOT_IT": "わかりました！"
        },
        "LABEL": {
          "EMAIL": "メール",
          "EMAIL_SENT_MESSAGE1": "パスワードをリセットするためのメールを指定されたアドレスに送信しました。",
          "EMAIL_SENT_MESSAGE2": "受信していない場合は、迷惑メールフォルダーを確認し、入力されたメールアドレスが正しいことを確認してください。",
          "EMAIL_SENT_TITLE": "回復メールを送信しました！",
          "SUCCESS": "アカウントが正常にリセットされました。"
        },
        "VALIDATION": {
          "EMAIL_INVALID": "無効なメールアドレスです",
          "EMAIL_MAX_LENGTH": "メールは{{value}}文字を超えてはなりません",
          "EMAIL_REQUIRED": "メールは必須です"
        }
      },
      "RESET_PASSWORD": {
        "ENTER_NEW_PASSWORD_DETAILS": "新しいパスワードの詳細を入力してください",
        "RESET_PASSWORD": "パスワードをリセット",
        "BUTTON": {},
        "LABEL": {
          "CONFIRM_NEW_PASSWORD": "新しいパスワードを確認",
          "CREATE_NEW_PASSWORD": "新しいパスワードを作成",
          "OLD_PASSWORD": "古いパスワード",
          "PASSWORD": "パスワード"
        },
        "VALIDATION": {
          "PASSWORDS_DONT_MATCH": "「パスワード」と「パスワードの確認」が一致しません",
          "PASSWORD_MAX_LENGTH": "パスワードは{{value}}文字を超えてはなりません",
          "PASSWORD_MIN_LENGTH": "パスワードは最低{{value}}文字でなければなりません",
          "PASSWORD_PATTERN": "大文字と小文字の両方、数字、および以下のいずれかを含む必要があります: -+_!@#$%^&*.,?",
          "PASSWORD_REQUIRED": "パスワードは必須です",
          "REGISTRATION_DETAILS_INCORRECT": "登録情報が正しくありません"
        }
      }
    },
    "MY_CONTACT_METHODS": {
      "CONTACT_PREFERENCES": "連絡の好み",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED": "連絡方法を選択してください。",
      "BUTTON": {},
      "LABEL": {
        "RECEIVE": "受け取る",
        "DO_NOT_RECEIVE": "受け取らない",
        "SALES_EMAIL": "営業メール",
        "EMAIL": "メール",
        "PHONE_CALL": "電話",
        "TEXT_MESSAGE": "テキストメッセージ"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "連絡の好みを更新中にエラーが発生しました"
      }
    },
    "MY_DATA_CONSENTS": {
      "MY_DATA_CONSENTS": "私のデータ同意",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_MANAGE_YOUR_DATA_CONSENTS": "データ同意の管理方法を選択してください。",
      "BUTTON": {},
      "LABEL": {
        "CONSENT": "同意",
        "WITHDRAW": "撤回",
        "DATA_CONSENT_PURPOSE": "データ同意の目的",
        "CONSENT_METHOD": "同意方法",
        "CONSENT_GIVEN": "同意済み",
        "CONSENT_EXPIRY": "同意の有効期限",
        "WITHDRAW_CONSENT": "同意を撤回"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "データ同意の更新中にエラーが発生しました"
      }
    }
  }
};
