// France
export const locale = {
  lang: "fr",
  "data": {
    "COMMON": {
      "CANCEL": "Annuler",
      "SUBMIT": "Soumettre",
      "NO": "Non",
      "YES": "Oui",
      "YES_CLOSE_AND_LOSE_CHANGES": "Oui, fermer et perdre les modifications",
      "CLOSE_AND_DISCARD": "Fermer et annuler",
      "SAVE_UPDATED_DETAILS": "Enregistrer les détails mis à jour",
      "VALIDATION": {
        "SOMETHING_WENT_WRONG": "Oups, quelque chose a mal tourné ! Veuillez réessayer.",
        "SUCCESSFULLY_UPDATED": "Mis à jour avec succès",
        "ANY_CHANGES_MADE_WILL_BE_LOST": "Toute modification apportée sera perdue.",
        "ARE_YOU_SURE_YOU_WANT_TO_PROCEED": "Êtes-vous sûr de vouloir continuer ?"
      }
    },
    "NAVBAR": {
      "HELLO": "Bonjour",
      "MY_DETAILS": "Mes informations",
      "MY_CONTACT_METHODS": "Mes méthodes de contact",
      "MY_DATA_CONSENTS": "Mes consentements de données",
      "LOGOUT": "Déconnexion",
      "SELECT_LANGUAGE": "Choisir la langue",
      "MENU": "Menu",
      "CLOSE": "Fermer",
      "SETTINGS": "Paramètres",
      "TERMSANDCONDITIONS": "Termes et conditions",
      "LANGUAGE": {
        "ENLABEL": "Anglais",
        "CHLABEL": "Chinois",
        "ESLABEL": "Espagnol",
        "JALABEL": "Japonais",
        "DELABEL": "Allemand",
        "FRLABEL": "Français"
      },
      "MENU_ITEM": {
        "CURRENT_PLACEMENT": "Mon poste actuel",
        "COMPLIANCE": "Ma conformité",
        "TIMESHEETS": "Mes feuilles de temps",
        "EXPENSES": "Mes dépenses"
      }
    },
    "AUTH": {
      "LOGIN": {
        "BUTTON": {
          "SIGN_IN": "Se connecter"
        },
        "LABEL": {
          "EMAIL": "Email",
          "PASSWORD": "Mot de passe",
          "FORGOT_PASSWORD": "Mot de passe oublié ?"
        },
        "VALIDATION": {
          "EMAIL_INVALID": "Adresse e-mail invalide",
          "EMAIL_MAX_LENGTH": "L'e-mail ne doit pas dépasser {{value}} caractères",
          "EMAIL_REQUIRED": "L'e-mail est requis",
          "LOGIN_FAILED": "Les détails de connexion sont incorrects",
          "PASSWORD_MAX_LENGTH": "Le mot de passe ne doit pas dépasser {{value}} caractères",
          "PASSWORD_MIN_LENGTH": "Le mot de passe doit comporter au moins {{value}} caractères",
          "PASSWORD_REQUIRED": "Le mot de passe est requis"
        }
      },
      "FORGOT_PASSWORD": {
        "ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD": "Entrez votre e-mail pour réinitialiser votre mot de passe",
        "FORGOTTEN_PASSWORD": "Mot de passe oublié ?",
        "BUTTON": {
          "OK_GOT_IT": "Ok, compris !"
        },
        "LABEL": {
          "EMAIL": "Email",
          "EMAIL_SENT_MESSAGE1": "Nous avons envoyé un e-mail à l'adresse que vous avez spécifiée pour réinitialiser votre mot de passe.",
          "EMAIL_SENT_MESSAGE2": "Si vous ne l'avez pas reçu, veuillez vérifier votre dossier de spam et également vérifier que l'adresse e-mail saisie était correcte.",
          "EMAIL_SENT_TITLE": "E-mail de récupération envoyé !",
          "SUCCESS": "Votre compte a été réinitialisé avec succès."
        },
        "VALIDATION": {
          "EMAIL_INVALID": "Adresse e-mail invalide",
          "EMAIL_MAX_LENGTH": "L'e-mail ne doit pas dépasser {{value}} caractères",
          "EMAIL_REQUIRED": "L'e-mail est requis"
        }
      },
      "RESET_PASSWORD": {
        "ENTER_NEW_PASSWORD_DETAILS": "Entrez les détails du nouveau mot de passe",
        "RESET_PASSWORD": "Réinitialiser le mot de passe",
        "BUTTON": {},
        "LABEL": {
          "CONFIRM_NEW_PASSWORD": "Confirmer le nouveau mot de passe",
          "CREATE_NEW_PASSWORD": "Créer un nouveau mot de passe",
          "OLD_PASSWORD": "Ancien mot de passe",
          "PASSWORD": "Mot de passe"
        },
        "VALIDATION": {
          "PASSWORDS_DONT_MATCH": "'Mot de passe' et 'Confirmer le mot de passe' ne correspondent pas",
          "PASSWORD_MAX_LENGTH": "Le mot de passe ne doit pas dépasser {{value}} caractères",
          "PASSWORD_MIN_LENGTH": "Le mot de passe doit comporter au moins {{value}} caractères",
          "PASSWORD_PATTERN": "Doit contenir des majuscules et minuscules, un chiffre et l'un des suivants : -+_!@#$%^&*.,?",
          "PASSWORD_REQUIRED": "Le mot de passe est requis",
          "REGISTRATION_DETAILS_INCORRECT": "Les détails de l'inscription sont incorrects"
        }
      }
    },
    "MY_CONTACT_METHODS": {
      "CONTACT_PREFERENCES": "Préférences de contact",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED": "Veuillez sélectionner comment vous souhaitez être contacté.",
      "BUTTON": {},
      "LABEL": {
        "RECEIVE": "Recevoir",
        "DO_NOT_RECEIVE": "Ne pas recevoir",
        "SALES_EMAIL": "Email de vente",
        "EMAIL": "Email",
        "PHONE_CALL": "Appel téléphonique",
        "TEXT_MESSAGE": "Message texte"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "Une erreur s'est produite lors de la mise à jour des préférences de contact"
      }
    },
    "MY_DATA_CONSENTS": {
      "MY_DATA_CONSENTS": "Mes Consentements de Données",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_MANAGE_YOUR_DATA_CONSENTS": "Veuillez sélectionner comment vous souhaitez gérer vos consentements de données.",
      "BUTTON": {},
      "LABEL": {
        "CONSENT": "Consentement",
        "WITHDRAW": "Retirer",
        "DATA_CONSENT_PURPOSE": "Objet du Consentement de Données",
        "CONSENT_METHOD": "Méthode de Consentement",
        "CONSENT_GIVEN": "Consentement Donné",
        "CONSENT_EXPIRY": "Expiration du Consentement",
        "WITHDRAW_CONSENT": "Retirer le Consentement"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "Une erreur s'est produite lors de la mise à jour de mes consentements de données"
      }
    }
  }
};
