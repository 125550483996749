// Spain
export const locale = {
  lang: "es",
  "data": {
    "COMMON": {
      "CANCEL": "Cancelar",
      "SUBMIT": "Enviar",
      "NO": "No",
      "YES": "Sí",
      "YES_CLOSE_AND_LOSE_CHANGES": "Sí, cerrar y perder cambios",
      "CLOSE_AND_DISCARD": "Cerrar y descartar",
      "SAVE_UPDATED_DETAILS": "Guardar detalles actualizados",
      "VALIDATION": {
        "SOMETHING_WENT_WRONG": "¡Vaya, parece que algo salió mal! Por favor, inténtalo de nuevo.",
        "SUCCESSFULLY_UPDATED": "Actualizado con éxito",
        "ANY_CHANGES_MADE_WILL_BE_LOST": "Cualquier cambio realizado se perderá.",
        "ARE_YOU_SURE_YOU_WANT_TO_PROCEED": "¿Estás seguro de que quieres continuar?"
      }
    },
    "NAVBAR": {
      "HELLO": "Hola",
      "MY_DETAILS": "Mis detalles",
      "MY_CONTACT_METHODS": "Mis métodos de contacto",
      "MY_DATA_CONSENTS": "Mis consentimientos de datos",
      "LOGOUT": "Cerrar sesión",
      "SELECT_LANGUAGE": "Seleccionar idioma",
      "MENU": "Menú",
      "CLOSE": "Cerrar",
      "SETTINGS": "Configuraciones",
      "TERMSANDCONDITIONS": "Términos y condiciones",
      "LANGUAGE": {
        "ENLABEL": "Inglés",
        "CHLABEL": "Chino",
        "ESLABEL": "Español",
        "JALABEL": "Japonés",
        "DELABEL": "Alemán",
        "FRLABEL": "Francés"
      },
      "MENU_ITEM": {
        "CURRENT_PLACEMENT": "Mi ubicación actual",
        "COMPLIANCE": "Mi cumplimiento",
        "TIMESHEETS": "Mis hojas de tiempo",
        "EXPENSES": "Mis gastos"
      }
    },
    "AUTH": {
      "LOGIN": {
        "BUTTON": {
          "SIGN_IN": "Iniciar sesión"
        },
        "LABEL": {
          "EMAIL": "Correo electrónico",
          "PASSWORD": "Contraseña",
          "FORGOT_PASSWORD": "¿Olvidaste tu contraseña?"
        },
        "VALIDATION": {
          "EMAIL_INVALID": "Correo electrónico inválido",
          "EMAIL_MAX_LENGTH": "El correo electrónico no debe superar los {{value}} caracteres",
          "EMAIL_REQUIRED": "El correo electrónico es obligatorio",
          "LOGIN_FAILED": "Los detalles de inicio de sesión son incorrectos",
          "PASSWORD_MAX_LENGTH": "La contraseña no debe superar los {{value}} caracteres",
          "PASSWORD_MIN_LENGTH": "La contraseña debe tener al menos {{value}} caracteres",
          "PASSWORD_REQUIRED": "La contraseña es obligatoria"
        }
      },
      "FORGOT_PASSWORD": {
        "ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD": "Introduce tu correo electrónico para restablecer tu contraseña",
        "FORGOTTEN_PASSWORD": "¿Contraseña olvidada?",
        "BUTTON": {
          "OK_GOT_IT": "¡Ok, entendido!"
        },
        "LABEL": {
          "EMAIL": "Correo electrónico",
          "EMAIL_SENT_MESSAGE1": "Hemos enviado un correo electrónico a la dirección que especificaste para restablecer tu contraseña.",
          "EMAIL_SENT_MESSAGE2": "Si no lo recibiste, por favor revisa tu carpeta de spam y verifica que la dirección de correo electrónico ingresada sea correcta.",
          "EMAIL_SENT_TITLE": "¡Correo de recuperación enviado!",
          "SUCCESS": "Tu cuenta ha sido restablecida con éxito."
        },
        "VALIDATION": {
          "EMAIL_INVALID": "Correo electrónico inválido",
          "EMAIL_MAX_LENGTH": "El correo electrónico no debe superar los {{value}} caracteres",
          "EMAIL_REQUIRED": "El correo electrónico es obligatorio"
        }
      },
      "RESET_PASSWORD": {
        "ENTER_NEW_PASSWORD_DETAILS": "Introduce los detalles de la nueva contraseña",
        "RESET_PASSWORD": "Restablecer contraseña",
        "BUTTON": {},
        "LABEL": {
          "CONFIRM_NEW_PASSWORD": "Confirmar nueva contraseña",
          "CREATE_NEW_PASSWORD": "Crear nueva contraseña",
          "OLD_PASSWORD": "Contraseña anterior",
          "PASSWORD": "Contraseña"
        },
        "VALIDATION": {
          "PASSWORDS_DONT_MATCH": "'Contraseña' y 'Confirmar contraseña' no coinciden",
          "PASSWORD_MAX_LENGTH": "La contraseña no debe superar los {{value}} caracteres",
          "PASSWORD_MIN_LENGTH": "La contraseña debe tener al menos {{value}} caracteres",
          "PASSWORD_PATTERN": "Debe contener letras mayúsculas y minúsculas, un número y uno de los siguientes: -+_!@#$%^&*.,?",
          "PASSWORD_REQUIRED": "La contraseña es obligatoria",
          "REGISTRATION_DETAILS_INCORRECT": "Los detalles de registro son incorrectos"
        }
      }
    },
    "MY_CONTACT_METHODS": {
      "CONTACT_PREFERENCES": "Preferencias de contacto",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED": "Por favor, selecciona cómo deseas ser contactado.",
      "BUTTON": {},
      "LABEL": {
        "RECEIVE": "Recibir",
        "DO_NOT_RECEIVE": "No recibir",
        "SALES_EMAIL": "Correo electrónico de ventas",
        "EMAIL": "Correo electrónico",
        "PHONE_CALL": "Llamada telefónica",
        "TEXT_MESSAGE": "Mensaje de texto"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "Hubo un error al actualizar las preferencias de contacto"
      }
    },
    "MY_DATA_CONSENTS": {
      "MY_DATA_CONSENTS": "Mis Consentimientos de Datos",
      "PLEASE_SELECT_HOW_YOU_WISH_TO_MANAGE_YOUR_DATA_CONSENTS": "Por favor seleccione cómo desea gestionar sus consentimientos de datos.",
      "BUTTON": {},
      "LABEL": {
        "CONSENT": "Consentimiento",
        "WITHDRAW": "Retirar",
        "DATA_CONSENT_PURPOSE": "Propósito del Consentimiento de Datos",
        "CONSENT_METHOD": "Método de Consentimiento",
        "CONSENT_GIVEN": "Consentimiento Otorgado",
        "CONSENT_EXPIRY": "Vencimiento del Consentimiento",
        "WITHDRAW_CONSENT": "Retirar Consentimiento"
      },
      "VALIDATION": {
        "ERROR_UPDATING": "Hubo un error al actualizar mis consentimientos de datos"
      }
    }
  }
};
